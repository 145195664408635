import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import EmailValidated from '../components/Registration/EmailValidated/EmailValidated';
import { navigate } from 'gatsby';
import { useAuth } from '../hooks/useAuth';

const EmailValidatedPage = ({ location }: { location: any }, { data }: any) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated() && typeof window !== 'undefined') {
    navigate('/');
    return null;
  }
  return (
    <>
      {user && (
        <>
          <Layout>
            <SEO title="Email validado" />
            <EmailValidated />
          </Layout>
        </>
      )}
    </>
  );
};

export default EmailValidatedPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
