import React from 'react';
import useStyles from './EmailValidated.styles';
import { Button, Container, Grid, Hidden, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NavBarV2 from '../../Header/NavBarV2';
import { navigate } from 'gatsby';

const EmailValidated = ({ uuid }: { uuid?: any }) => {
  const classes = useStyles();
  const handleClick = () => {
    navigate('/signup-form');
  };
  return (
    <section id="" className={classes.root}>
      <NavBarV2 />
      <Container className={classes.container} maxWidth={false}>
        <Grid container>
          <Hidden smDown>
            <Grid item md={6} className={classes.feather} />
          </Hidden>

          <Grid className={classes.rightCol} item xs={12} md={6} lg={4}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <h1>¡Gracias! Ahora sabemos que no eres un robot :)</h1>
              </Grid>
              <Grid item xs={12}>
                <p>Continúa completando tu perfil con tu cédula de identidad a mano.</p>
              </Grid>
              <Grid item xs={12}>
                <p>
                  Te pediremos tu <strong>RUT</strong> y <strong>número de documento</strong> para
                  cumplir con las regulaciones legales y poder ofrecerte un servicio más rápido,
                  eficiente y seguro.
                </p>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={handleClick}
                  className={classes.button}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};
export default EmailValidated;
